<template>
    <modal ref="reportarPago" titulo="Reportar pago" adicional="Guardar" @adicional="reportarPago">
        <ValidationObserver ref="validator">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-9 mb-3">
                        <ValidationProvider name="fecha de pago" rules="required" v-slot="{ errors }">
                            <p class="input-label-top">Fecha de pago</p>
                            <el-date-picker v-model="model.fecha_pago" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                    <div class="col-9 mb-3">
                        <div class="d-middle align-items-start gap-2">
                            <ValidationProvider name="moneda" rules="required" v-slot="{ errors }">
                                <p class="input-label-top">Selecciona moneda</p>
                                <el-select v-model="model.id_moneda" placeholder="Seleccionar" size="small" class="w-100">
                                    <el-option v-for="item in select_monedas" :key="item.id" :label="item.nombre" :value="item.id" />
                                </el-select>
                            </ValidationProvider>
                            <ValidationProvider name="valor a pagar" rules="required|min_value:0.01" v-slot="{ errors }">
                                <p class="input-label-top">Valor a pagar</p>
                                <CustomCurrencyInput v-model="model.pago" :options="{ currency: 'USD' }" placeholder="Valor a pagar"/>
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
const model = {
    fecha_pago: '',
    id_moneda: null,
    pago: ''
}
export default {
    data(){
        return {
            model: {...model}
        }
    },
    computed: {
        ...mapGetters({
            select_monedas: 'selects/selects/select_monedas',
        })
    },
    methods: {
        toggle(){
            this.$refs.reportarPago.toggle()
        },
        reset(){
            this.model = {...model}
            this.$refs.validator.reset()
        },
        async reportarPago(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return
            
            const payload = {
                fecha_pago: moment(this.model.fecha_pago).format('YYYY-MM-DD'),
                pago: this.model.pago,
                id_moneda: this.model.id_moneda
            }

            this.$emit('report', payload)
        }
    }
}
</script>

<style lang="css" scoped>
</style>
