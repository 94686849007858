<template>
    <section class="obras-servicios-forma-pago container-fluid custom-scroll overflow-auto">
        <div class="row my-4">
            <div class="col-10">
                <indicador-progress-value :totalValue="100000" :actualValue="10000" />
            </div>
            <div class="col-2">
                <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                    <template #content>
                        <p class="p-2 cr-pointer item" @click="reportarPago">Reportar pago</p>
                        <p class="p-2 cr-pointer item" @click="finalizarPago">Finalizar pago</p>
                    </template>
                    <button class="btn btn-general px-3 f-12">Actualizar pago</button>
                </el-tooltip>
            </div>
        </div>
        <div class="container mt-5">
            <div class="row f-14 my-4 mx-0">
                <div class="col-6">
                    <p class="f-600 pl-4">
                        Forma de pago:
                        <span class="f-300"> Cuotas </span>
                    </p>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <p>Fijar cuota por:</p>
                    <div class="my-auto ml-2">
                        <el-radio v-model="radio" label="1" class="f-12">Porcentaje</el-radio>
                        <el-radio v-model="radio" label="2" class="f-12">Valor</el-radio>
                    </div>
                </div>
            </div>
            <div class="row mt-4 mx-0 bg-general text-white py-2 justify-content-between">
                <div class="col-2 my-auto">
                    <p class="f-600 f-14">Cuotas</p>
                </div>
                <div class="col-4 my-auto">
                    <p class="f-600 f-14">Fecha de pago</p>
                </div>
                <div v-if="radio === '1'" class="col-2 my-auto">
                    <p class="f-600 f-14">Porcentaje</p>
                </div>
                <div v-if="radio === '2'" class="col-2 my-auto">
                    <p class="f-600 f-14">Valor</p>
                </div>
                <div class="col-auto my-auto">
                    <el-popover v-model="visibleAgregarCuotas" placement="bottom-end" width="200" trigger="click" class="px-0">
                        <div class="row mx-0 justify-content-center f-600 text-general">
                            Añadir cuotas
                        </div>
                        <hr class="my-1 bg-general" />
                        <div class="row mx-0 justify-content-center">
                            <div class="col-10 my-2">
                                <p class="input-label-top">Número de cuotas</p>
                                <el-input v-model="inputNumCuotas" class="f-12 br-5 w-100" size="small" />
                            </div>
                        </div>
                        <div class="text-right m-0 mt-2">
                            <button class="btn btn-block btn-crear f-12" @click="agregarCuotas">Añadir</button>
                        </div>
                        <el-tooltip slot="reference" content="Añadir cuotas" effect="light" placement="bottom" visible-arrow>
                            <i class="icon-plus-box cr-pointer f-20" />
                        </el-tooltip>
                    </el-popover>
                </div>
            </div>
            <div v-for="(data,i) in 5" :key="i" class="row border-bottom mx-0 py-2 justify-content-between">
                <div class="col-2 my-auto">
                    <p class="f-12">Cuota {{ i+1 }}</p>
                </div>
                <div class="col-4 my-auto">
                    <el-date-picker v-model="datePicker" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div v-if="radio === '1'" class="col-2 my-auto">
                    <el-input v-model="inputPorcentaje" class="w-100" size="small" placeholder="Porcentaje" />
                </div>
                <div v-if="radio === '2'" class="col-2 my-auto">
                    <el-input v-model="inputValorCuota" class="w-100" size="small" placeholder="Valor" />
                </div>
                <div class="col-auto my-auto">
                    <i class="icon-close-circle cr-pointer f-20 text-ddd" />
                </div>
            </div>
            <div class="row mt-4 mx-0 py-2 justify-content-end">
                <div class="col-2 my-auto" />
                <div class="col-4 my-auto text-right">
                    <span class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">Total</span>
                </div>
                <div v-if="radio === '1'" class="col-2 my-auto">
                    <div class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">100%</div>
                </div>
                <div v-if="radio === '2'" class="col-3 my-auto">
                    <div class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">{{this.formatoMoneda(85000)}}</div>
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-reportar-pago ref="reportarPago" />
        <modal ref="abrirModalFinalizarPago" titulo="Finalizar pago" adicional="Finalizar" @adicional="finalizarPago">
            <div class="row m-3 f-12">
                <p class="col-12 text-center">¿Está seguro que quiere finalizar este pago?</p>
            </div>
        </modal>
    </section>
</template>

<script>
import modalReportarPago from '~/pages/compras/partials/modalReportarPago'
export default {
    components:{
        modalReportarPago
    },
    data(){
        return{
            radio: '2',
            inputNumCuotas: '',
            datePicker: '',
            inputPorcentaje: '',
            inputValorCuota: '',
            visibleAgregarCuotas: false,
        }
    },
    methods:{
        reportarPago(){
            this.$refs.reportarPago.toggle()
        },
        finalizarPago(){
            this.$refs.abrirModalFinalizarPago.toggle()
        },
        agregarCuotas(){
            this.visibleAgregarCuotas = !this.visibleAgregarCuotas
        }
    }

}
</script>

<style>

</style>